import { Avatar, Button, Dropdown, Select } from "antd";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";

import { useGetProfile } from "../hooks/useAuth";
import {
  ScreenControlProvider,
  useScreenControl,
} from "../hooks/useDeviceControl";
import useLocalStorage from "../hooks/useLocalStorage";
import { SystemInfoProvider, useSystemInfo } from "../hooks/useSystemInfo";
import { tt } from "../utils";
import Axios from "../utils/axios";
import { USER_TYPES } from "./ConsoleLayout";

export default function W3Layout() {
  return (
    <SystemInfoProvider>
      <ScreenControlProvider>
        <>
          <Content />
        </>
      </ScreenControlProvider>
    </SystemInfoProvider>
  );
}

function Content() {
  const info = useSystemInfo();
  const { t } = useTranslation();
  const profile = useGetProfile();
  const { size } = useScreenControl();

  const [section, setSection] = useState("home");

  return (
    <>
      <header
        className="grid items-center gap-4 py-2 px-4 bg-white top-border"
        style={{ height: 55, gridTemplateColumns: "auto 600px auto" }}
      >
        <div className="flex items-center pl-6">
          <Link
            to="/"
            className="text-xl font-bold no-underline text-[#9345f4]"
          >
            {tt(t, info.name)}
          </Link>
        </div>
        <div className="flex justify-center gap-8 cursor-pointer">
          {[
            ["home", "homeContainer", "Home"],
            ["services", "servicesContainer", "Services"],
            // ["clients", "clientsContainer", "Clients"],
            // ["testimonials", "testimonialsContainer", "Testimonials"],
            ["about", "aboutContainer", "About"],
            ["contact", "contactContainer", "Contact"],
          ].map(([key, containerId, label]) => (
            <div key={key} className="text-center" style={{ minWidth: 80 }}>
              <a
                href={`#${containerId}`}
                className={`no-underline hover:text-[rgb(158,63,253)] ${
                  section === key ? "rgb(158,63,253)" : "text-zinc-600"
                }`}
                onClick={() => {
                  setSection(key);
                }}
              >
                {tt(t, label)}
              </a>
            </div>
          ))}
        </div>
        <div className="flex justify-end items-center gap-4">
          {profile._id && (
            <>
              <Link to="/console">
                <Button type="link">{tt(t, "Console")}</Button>
              </Link>
            </>
          )}

          {/* <LanguageSelector /> */}

          {profile._id && (
            <>
              <div>
                <TopUserMenu profile={profile} />
              </div>
            </>
          )}
        </div>
      </header>
      <div
        id="mainContainer"
        className="overflow-y-auto"
        style={{ height: size.height - 71 }}
      >
        <Outlet context={{ profile, setSection }} />
      </div>
    </>
  );
}

export function TopUserMenu({ profile }: { profile: any }) {
  const { t } = useTranslation();
  const icon = useMemo(() => {
    if (profile.avatarMd5) {
      return { src: `/api/storage/gridfs/md5/${profile.avatarMd5}` };
    } else {
      return { icon: <UserOutlined /> };
    }
  }, [profile.avatarMd5]);
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "1",
            label: (
              <Link to="/console/profile">
                <Button type="link">{t("Profile")}</Button>
                <div className="text-xs text-zinc-400 pl-5 -m-1 mb-1">
                  {profile.email}
                </div>
              </Link>
            ),
          },
          {
            key: "2",
            label: (
              <Button
                type="link"
                onClick={() =>
                  Axios.post("/api/auth/logout").then(
                    () => (window.location.href = "/")
                  )
                }
              >
                {t("Logout")}
              </Button>
            ),
          },
          {
            type: "divider",
          },
          {
            key: "3",
            disabled: true,
            label: (
              <div className="text-xs text-right">
                {USER_TYPES[profile.type]}.1.0
              </div>
            ),
          },
        ],
      }}
      dropdownRender={(menu) => React.cloneElement(menu as React.ReactElement)}
    >
      <Avatar className="cursor-pointer -my-2" size="large" {...icon} />
      {/* <Button>{profile.name}</Button> */}
    </Dropdown>
  );
}
const langs: any = {
  en: "English",
  zh: "简体中文",
};
export function LanguageSelector() {
  const [settings, setSettings] = useLocalStorage("settings", { lang: "en" });

  return (
    <Select
      onChange={(value: string) => {
        setSettings({ ...settings, lang: value });
        // i18next.changeLanguage(value);
        window.location.reload();
      }}
      defaultValue={settings.lang}
      options={Object.keys(langs).map((lang: string, index: number) => ({
        value: lang,
        label: langs[lang],
      }))}
    ></Select>
  );
}
